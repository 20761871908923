import { getFooterListingSearchItems } from 'lib/template'
import { getWhatsappUrl } from 'lib/user'
import { TListing } from './types'

type TFooterNavParams = {
  listing?: TListing
  openEmailModal: () => void
}

type TListItem = {
  label: string
  hidden?: boolean
  target?: string
  href?: string
  onClick?: () => void
}

type TFooterNav = Array<{
  title: string
  hidden?: boolean
  list: Array<TListItem>
}>

export const ABOUT = 'Todos os imóveis, com os melhores corretores.'

export const FOOTER_SOCIAL = [
  {
    name: 'Linkedin',
    slug: 'linkedin',
    href: 'https://www.linkedin.com/company/emcasa',
  },
  {
    name: 'Facebook',
    slug: 'facebook',
    href: 'https://www.facebook.com/EmCasa',
  },
  {
    name: 'Instagram',
    slug: 'instagram',
    href: 'https://www.instagram.com/emcasa',
  },
]

export const FOOTER_NAV = ({
  listing,
  openEmailModal,
}: TFooterNavParams): TFooterNav => [
  {
    title: 'Buscar imóveis',
    list: [
      {
        label: 'Ver imóveis próximos',
        href: '/imoveis/perto-de-voce',
        hidden: !!listing,
      },
      {
        label: 'Buscar imóveis',
        href: '/imoveis',
        hidden: !!listing,
      },
      ...getFooterListingSearchItems(listing),
      {
        label: 'Ver regiões atendidas',
        href: '/regioes-atendidas',
      },
    ],
  },
  {
    title: 'Vender imóvel',
    hidden: !!listing,
    list: [
      {
        label: 'Vender imóvel',
        href: '/vender',
      },
      {
        label: 'Avaliar imóvel',
        href: '/avaliar',
      },
      {
        label: 'Parceria',
        href: '/parceria',
      },
    ],
  },
  {
    title: 'Atendimento',
    list: [
      {
        label: 'WhatsApp',
        href: getWhatsappUrl({ phone: '551131976638' }),
        target: '_blank',
      },
      {
        label: 'E-mail',
        onClick: openEmailModal,
      },
    ],
  },
  {
    title: 'Sobre',
    hidden: !!listing,
    list: [
      {
        label: 'Carreira',
        href: 'https://www.linkedin.com/company/emcasa/jobs/',
        target: '_blank',
      },
      {
        label: 'Blog',
        href: '/blog?nocache=sw',
      },
      { label: 'Mapa do site', href: '/sitemap' },
      { label: 'Política de Privacidade', href: '/legal' },
    ],
  },
]
